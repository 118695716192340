<template>
  <OnboardPage 
    :content="content"
  >
    <Notification
      v-if="error"
      type="danger"
    >
      {{ error }}
    </Notification>
    <img
      v-if="business.image"
      :src="business.image"
      style="margin-bottom: 10px;"
    />
    <div 
      class="buttons are-medium"
    >
      <BaseUpload
        label="Upload image"
      />
      <button
        v-if="business.image"
        class="button is-link"
        style="margin-left: 10px;"
        @click="next()"
      >
        Keep
      </button>
      <button
        v-else
        class="button"
        style="margin-left: 10px;"
        @click="next()"
      >
        Skip
      </button>
    </div>

    <div
     style="margin-top:20px;"
    >
      <a
        v-if="content.showBack" 
        class="is-size-7 has-text-grey"
        @click="back()"
      >
        &larr;
        back
      </a>
    </div>
  </OnboardPage>
</template>

<script>
import {AuthError, NetworkError} from "@/errors";
import OnboardPage from "@/components/OnboardPage.vue";
import Notification from "@/components/Notification.vue";
import BaseUpload from "@/components/form/BaseUpload.vue";

export default {
  components: {
    OnboardPage,
    Notification,
    BaseUpload
  },
  data() {
    return {
      loading: false,
      error: null,
      submitting: false,
      business: {
        image: '',
      },
      content: {
        page: 'organization-image',
        step: 2,
        title: 'Upload an image',
        subtitle: 'Add an image for your business (GIF, JPEG, or PNG)',
        sideComponentText: 'Attract more customers. Only pay when a customer purchases and claims the deal.',
        nextPage: "organization-deal-category",
        buttons: [
          {
            class: "button",
            label: "Upload image",
            placeholder: "",
            value: "",
            action: "cloudinary"
          },
          {
            class: "button",
            label: "Skip",
            placeholder: "",
            value: "organization-deal-category",
            action: "next"
          },
        ],
        showBack: true
      }
    }
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser;     
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness;     
    },
  },
  mounted() {
    if (this.currentBusiness && this.currentBusiness.slug) {

      if (this.currentBusiness.is_onboarded == true) {
        this.$router.push('/deals/user/my-location/100')
      } else {
        this.business = this.currentBusiness
      }  

    } else {
      this.$router.push("/o/organization-new")
    } 
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    next() {
      this.$router.push(this.content.nextPage)
    },
    uploadImg(image_url) {
      this.business.image = image_url
      this.submitting = true;
      const params = {
        user_slug: this.currentUser.slug,
        slug: this.currentBusiness.slug,
        organization: this.business
      }
      this.$store.dispatch("updateBusiness", params)
        .then(() => {
          this.submitting = false
          this.$router.push('/o/' + this.content.nextPage)
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            console.log(error)
            this.error = error.message
          }
        })
    }
  }
}
</script>
